import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Layout from 'components/layout'
import Title from 'components/title'
import { dateFormatted } from 'utils/functions'
import { graphql } from 'gatsby'

const Press = ({ press, locale }) => {
  return (
    <li>
      <div className="small-caps date">
        {dateFormatted(press.datePosted, locale)}
      </div>
      <div className="title">
        <LinkWrap to={`/press/${press.slug}`} className="dark">
          {press.title}
        </LinkWrap>
      </div>
    </li>
  )
}

const PressPage = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  // const translations = data.translations;
  // const tr = (code) => trFunction(code, translations, locale);
  const allPress = data.allPress.edges
  const thisPage = data.thisPage

  return (
    <Layout>
      <div id="content">
        <Title title={thisPage.title} />
        <section className="page-content wrap">
          <ul className="press-list">
            {allPress.map((edge, i) => (
              <Press key={i} press={edge.node} locale={locale} />
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  )
}
export default PressPage

export const pressPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allPress: allContentfulPress(sort: { fields: [datePosted], order: DESC }) {
      edges {
        node {
          datePosted
          title
          slug
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "press" }) {
      title
      slug
    }
  }
`
